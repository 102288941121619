<template>
  <div class="help-section">
    <p class="bold-font emerald">Set the price for each unit added.</p>
    <div>
      Define the cost for the first unit and adjust pricing for additional units. Clients will select how many units they need when signing the contract.
    </div>
    <div class="bold-font mt-10">How Pricing Works:</div>
    <ul class="grey-text text-darken-2">
      <li>• The <span class="emerald">first unit</span> has a base price (e.g., $30).</li>
      <li>• Each <span class="emerald">additional unit</span> can have a different price (e.g., second unit $20, third unit $10).</li>
      <li>• Clients choose how many units to include in their contract.</li>
      <li>• You can <span class="emerald">limit the maximum units</span> per contract.</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Adjust pricing to reflect discounts for multiple units if needed.
    </div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentsName'
}
</script>
