<template>
  <section class="center">
    <!-- Validation Errors -->
    <div
      v-if="showValidationErrors"
      class="card-panel red lighten-4 red-text text-darken-4 mb-20"
    >
      Please fill in all required fields correctly:
      <ul class="mt-10">
        <li v-if="!store.productData.name">• Contract Name is required</li>
        <li v-if="!store.productData.description">
          • Description is required
        </li>
        <li v-if="!store.productData.amount || store.productData.amount <= 0">
          • Amount must be greater than 0
        </li>
      </ul>
    </div>

    <!-- Form Content -->
    <div class="card-content">
      <h3 class="bold-font">Create New Contract template</h3>
      <div class="input-field mt-35">
        <label for="name">Contract Name (required)</label>
        <input
          id="name"
          v-model="store.productData.name"
          @focus="store.activeHelpScreen = 'productName'"
          type="text"
          placeholder="e.g. HVAC Quarterly Maintenance Agreement"
        />
      </div>
      <div class="input-field">
        <label for="description">Description (required)</label>
        <input
          id="description"
          v-model="store.productData.description"
          @focus="store.activeHelpScreen = 'productDescription'"
          type="text"
          placeholder="e.g. Regular maintenance for commercial HVAC systems, including filter replacement"
        />
      </div>
      <div class="input-field">
        <label for="amount">Amount (required)</label>
        <input
          id="amount"
          v-model.number="store.productData.amount"
          @focus="store.activeHelpScreen = 'baseAmount'"
          type="number"
          placeholder="e.g. 20"
        />
      </div>
      <br>

      <!-- Recurring Schedule Section -->
      <div v-if="store.productData.recurring" class="input-field mt-25">
        <section class="card-panel">
          <div class="bold-font">Recurring schedule:</div>
          <div class="row mt-10">
            <div class="col s12 m3 mt-10">
              <!-- Clear "on" when frequency changes -->
              <select
                v-model="store.productData.frequency"
                class="browser-default"
                @change="store.productData.on = []"
              >
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
            <div class="col s6 m3 mt-10">
              Every:
              <input
                v-model="store.productData.each"
                type="number"
                style="width: 30px; margin-top: -30px; margin-left: 10px"
              />
              {{ placeholders.frequency }}
            </div>
            <div class="col s12 m3" style="margin-top: -10px">
              <label for="start-date">Repeat</label>
              <select v-model="store.productData.count" class="browser-default">
                <option :value="null">Unlimited</option>
                <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
              </select>
            </div>
          </div>

          <div class="center">
            <span
              class="on"
              :class="activeOn(item[0])"
              @click="addOn(item[0])"
              v-for="item in placeholders.on"
              :key="item[0]"
            >
              {{ item[1] }}
            </span>
          </div>

          <div class="center emerald mt-10" v-if="!recurringStringError">
            Charge {{ store.productData.amount | currency }} {{ recurringString }}
            <span v-if="totalAmountForRepeat">
              for a total of {{ totalAmountForRepeat | currency }}
            </span>
          </div>

          <div class="center red-text mt-10" v-else>
            {{ recurringStringError }}
          </div>
        </section>
      </div>
    </div>

    <!-- Action Button -->
    <div class="card-action">
      <button
        class="btn"
        @click="attemptNext"
        :disabled="!isFormValid"
        :class="{ disabled: !isFormValid }"
      >
        Next: Define Contract Properties
      </button>
    </div>
  </section>
</template>

<script>
import gql from "graphql-tag";
import { ref, computed, onMounted, nextTick } from "@vue/composition-api";
import { useProductStore } from "../product_store";

export default {
  name: "ProductDetails",
  setup() {
    const store = useProductStore();
    const showValidationErrors = ref(false);
    const recurringStringError = ref(null);

    // Navigation
    const nextStep = () => {
      store.activeStep = "productProperties";
      store.activeHelpScreen = "productProperties";
    };

    // Form Validation
    const isFormValid = computed(() => {
      return (
        store.productData.name &&
        store.productData.name.trim() &&
        store.productData.description &&
        store.productData.description.trim() &&
        store.productData.amount >= 0
      );
    });

    const attemptNext = () => {
      if (isFormValid.value) {
        nextStep();
      } else {
        showValidationErrors.value = true;
      }
    };

    // Placeholders for recurring schedule
    const placeholders = computed(() => {
      switch (store.productData.frequency) {
        case "weekly":
          return {
            frequency: "week",
            on: [
              [0, "Sun"],
              [1, "Mon"],
              [2, "Tue"],
              [3, "Wed"],
              [4, "Thu"],
              [5, "Fri"],
              [6, "Sat"]
            ]
          };
        case "monthly":
          return {
            frequency: "month",
            on: Array.from({ length: 31 }, (_, i) => [i + 1, i + 1])
          };
        case "yearly":
          return {
            frequency: "year",
            on: Array.from({ length: 31 }, (_, i) => [i + 1, i + 1])
          };
        default:
          return { frequency: "", on: [] };
      }
    });

    // Total amount for recurring repeat
    const totalAmountForRepeat = computed(() => {
      if (store.productData.count && store.productData.amount) {
        return store.productData.amount * store.productData.count;
      }
      return null;
    });

    // Methods for recurring day selection
    const activeOn = (item) =>
      store.productData.on.includes(item) ? "activeOn" : "";
    const addOn = (item) => {
      if (store.productData.on.includes(item)) {
        store.productData.on = store.productData.on.filter(
          (e) => e !== item
        );
      } else {
        store.productData.on.push(item);
      }
    };

    onMounted(async () => {
      await nextTick();
      M.updateTextFields();
    });

    return {
      store,
      showValidationErrors,
      recurringStringError,
      nextStep,
      attemptNext,
      isFormValid,
      placeholders,
      totalAmountForRepeat,
      activeOn,
      addOn
    };
  },
  apollo: {
    recurringString: {
      fetchPolicy: "no-cache",
      query: gql`
        query($frequency: String!, $each: Int!, $on: [Int!], $count: Int) {
          merchant {
            recurringString(frequency: $frequency, each: $each, on: $on, count: $count)
          }
        }
      `,
      variables() {
        return {
          frequency: this.store.productData.frequency,
          each: parseInt(this.store.productData.each),
          on: this.store.productData.on,
          count: this.store.productData.count
            ? parseInt(this.store.productData.count)
            : null
        };
      },
      update(data) {
        this.recurringStringError = null;
        // The returned value from the query will be assigned to "recurringString"
        this.recurringStringError = null;
        this.store.productData.recurringString = data.merchant.recurringString;
        return data.merchant.recurringString;
      },
      error() {
        this.recurringStringError = "Invalid recurring string";
        this.store.productData.recurringString = '';
      }
    }
  },
  filters: {
    currency(value) {
      if (!value) return "";
      return "$" + parseFloat(value).toFixed(2);
    }
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.on {
  border: 2px solid #00ab8e;
  height: 30px;
  cursor: pointer;
  width: 45px;
  display: inline-block;
  margin-left: 2px;
  margin-top: 2px;
  color: #00ab8e;
}
.activeOn {
  color: white;
  background-color: #00ab8e;
}
</style>
