<template>
  <div class="help-section">
    <p class="bold-font emerald">Set the base price for this contract.</p>
    <div>
      This is the starting cost before adding any equipment or services. On the next step, you'll define the maximum number of units allowed and the price for each additional unit.
    </div>
    <div class="bold-font mt-10">How Pricing Works:</div>
    <ul class="grey-text text-darken-2">
      <li>• Base price applies to every contract.</li>
      <li>• Each unit (e.g., HVAC system, appliance, service) can have its own price.</li>
      <li>• Total cost = Base price + Unit 1 price + Unit 2 price, etc.</li>
      <li>• The client will choose how many units to include when signing.</li>
      <li>• You can set a limit on the maximum number of units allowed on the next steps.</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Make sure the base price reflects the minimum service or coverage included.
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAmount'
}
</script>
