<template>
  <div>
    <div class="row">
      <div class="col s12 m8">
        <div class="card">
          <component :is="currentStepComponent"></component>
        </div>
      </div>
      <div class="col s12 m4">
        <transition name="help-slide" mode="out-in">
          <component :is="currentStepHelpComponent" :key="store.activeStep"></component>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useProductStore } from './product_store'

import ProductDetails from './components/details.vue'
import ProductProperties from './components/properties.vue'
import BaseEquipments from './components/base_equipments.vue'
import ViewContract from './components/view_contract'

import ProductName from './components/help/product_name.vue'
import BaseAmount from './components/help/base_amount.vue'
import ProductDescription from './components/help/product_description.vue'
import ProductPropertiesHelp from './components/help/properties_help.vue'
import ProductPropertiesLabel from './components/help/properties_label.vue'
import EquipmentsHelp from './components/help/equipments_help.vue'
import EquipmentName from './components/help/equipment_name.vue'
import EquipmentPrice from './components/help/equipment_price.vue'
import EquipmentProperty from './components/help/equipment_property.vue'

export default {
  name: 'CreateProduct',
  setup() {
    const store = useProductStore();

    const stepComponentMap = {
      firstStep: ProductDetails,
      productProperties: ProductProperties,
      baseEquipments: BaseEquipments,
      viewContract: ViewContract
    }

    // Determine which main component to show based on the active step
    const currentStepComponent = computed(() => {
      return stepComponentMap[store.activeStep] || ProductDetails
    })

    const helpComponentMap = {
      productName: ProductName,
      baseAmount: BaseAmount,
      productDescription: ProductDescription,
      productProperties: ProductPropertiesHelp,
      productPropertiesLabel: ProductPropertiesLabel,
      baseEquipments: EquipmentsHelp,
      equipmentName: EquipmentName,
      equipmentPrice: EquipmentPrice,
      equipmentProperty: EquipmentProperty,
      viewContract: null // No help component for success step
    }

    // Determine which help component to show based on the active help screen
    const currentStepHelpComponent = computed(() => {
      return helpComponentMap[store.activeHelpScreen]
    })

    return {
      store,
      currentStepComponent,
      currentStepHelpComponent
    }
  }
}
</script>

<style scoped>
.help-slide-enter-active,
.help-slide-leave-active {
  transition: all 0.3s ease;
}

.help-slide-enter {
  opacity: 0;
  transform: translateX(20px);
}

.help-slide-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
</style>
