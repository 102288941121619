<template>
  <section class="center">
    <!-- Validation Errors -->
    <div
      v-if="showValidationErrors"
      class="card-panel red lighten-4 red-text text-darken-4 mb-20"
    >
      Please fill in all required fields correctly:
      <ul class="mt-10">
        <li v-if="productPropertiesCount === 0">
          • At least one property is required
        </li>
        <li
          v-for="(property, index) in store.productProperties"
          :key="index"
        >
          <template v-if="!property.label">
            • Property {{ index + 1 }} label is required
          </template>
        </li>
      </ul>
    </div>

    <!-- Form Content -->
    <div class="card-content">
      <h3 class="bold-font center-align">Define Contract Properties</h3>
      <div
        v-for="(property, index) in store.productProperties"
        :key="index"
        class="card"
      >
        <div class="card-content" style="position: relative;">
          <i
            class="fas fa-times grey-text text-darken-1"
            @click="removeProductProperty(index)"
            style="position: absolute; top: 10px; right: 10px; cursor: pointer; font-size: 18px;"
            title="Remove property"
          ></i>
          <div class="input-field">
            <label :for="`prop_label_${index}`">Label</label>
            <input
              :id="`prop_label_${index}`"
              v-model="property.label"
              @focus="store.activeHelpScreen = 'productPropertiesLabel'"
              type="text"
              placeholder="Client name"
            />
          </div>
          <div class="input-field" style="display: none;">
            <label :for="`prop_field_type_${index}`">Field Type</label>
            <select :id="`prop_field_type_${index}`" v-model="property.field_type">
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
            </select>
          </div>
          <div class="input-field">
            <label>
              <input type="checkbox" v-model="property.required" />
              <span>Required</span>
            </label>
          </div>
          <br />
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="mt-20 action-buttons">
        <button
          v-if="store.productProperties.length === 0"
          class="btn grey lighten-1 back-btn"
          @click="back"
        >
          <i class="fas fa-arrow-left"></i>
          <span>BACK</span>
        </button>
        <button
          class="btn teal add-btn"
          @click="addProductProperty"
        >
          <i class="fas fa-plus"></i>
          <span>ADD PROPERTY</span>
        </button>
        <button
          v-if="store.productProperties.length === 0"
          class="btn grey lighten-1 skip-btn" 
          @click="skipProperties"
        >
          <i class="fas fa-forward"></i>
          <span>SKIP</span>
        </button>
      </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="card-action navigation-buttons" v-if="store.productProperties.length > 0">
      <button class="btn grey lighten-1 back-btn" @click="back">
        <i class="fas fa-arrow-left"></i>
        <span>BACK</span>
      </button>
      <button
        class="btn teal next-btn"
        @click="nextStep"
        :disabled="!isFormValid"
        :class="{ disabled: !isFormValid }"
      >
        <span>NEXT: ADD EQUIPMENT</span>
      </button>
    </div>
  </section>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "@vue/composition-api";
import { useProductStore } from "../product_store";

export default {
  name: "ProductProperties",
  setup() {
    const store = useProductStore();
    const showValidationErrors = ref(false);

    const addProductProperty = async () => {
      store.addProductProperty();
      await nextTick();
      // Update Materialize text fields after DOM update
      M.updateTextFields();
    };

    const removeProductProperty = (index) => {
      store.removeProductProperty(index);
    };

    const back = () => {
      store.activeHelpScreen = null;
      store.activeStep = "firstStep";
    };

    const nextStep = () => {
      if (isFormValid.value) {
        showValidationErrors.value = false;
        store.activeStep = "baseEquipments";
        store.activeHelpScreen = "baseEquipments";
      } else {
        showValidationErrors.value = true;
      }
    };

    const skipProperties = () => {
      // Clear any existing properties
      store.productProperties = [];
      // Move to the next step
      store.activeStep = "baseEquipments";
      store.activeHelpScreen = "baseEquipments";
    };

    const isFormValid = computed(() => {
      // Ensure there is at least one property and that every property has a non-empty label.
      return (
        store.productProperties.length > 0 &&
        store.productProperties.every(
          (property) => property.label && property.label.trim()
        )
      );
    });

    const productPropertiesCount = computed(
      () => store.productProperties.length
    );

    onMounted(async () => {
      await nextTick();
      M.updateTextFields();
    });

    return {
      store,
      showValidationErrors,
      addProductProperty,
      removeProductProperty,
      back,
      nextStep,
      skipProperties,
      isFormValid,
      productPropertiesCount
    };
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.action-buttons .btn {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 16px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  margin: 5px;
  flex: 1;
  min-width: 100px;
  max-width: 200px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.navigation-buttons .btn {
  text-transform: uppercase;
  font-weight: 500;
  flex: 1;
  margin: 5px;
  min-width: 120px;
}

/* Mobile responsiveness */
@media only screen and (max-width: 600px) {
  .action-buttons, .navigation-buttons {
    flex-direction: column;
  }
  
  .action-buttons .btn, .navigation-buttons .btn {
    width: 100%;
    max-width: 100%;
  }
}
</style>
