<template>
  <div class="help-section">
    <p class="bold-font emerald">Add custom fields for this equipment.</p>
    <div>
      These fields allow you to collect specific details for each piece of equipment a client selects. Clients will fill them in when signing the contract.
    </div>
    <div class="bold-font mt-10">Common Equipment Properties:</div>
    <ul class="grey-text text-darken-2">
      <li>• Serial Number (for tracking equipment)</li>
      <li>• Model (to specify the exact unit)</li>
      <li>• Installation Date (for service scheduling)</li>
      <li>• Warranty Expiration (to track coverage)</li>
      <li>• Custom Notes (any extra information from the client)</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Only add relevant fields to keep the process simple for clients.
    </div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentsName'
}
</script>
