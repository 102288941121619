import { defineStore } from 'pinia'

export const useProductStore = defineStore('productStore', {
  state: () => ({
    activeStep: 'firstStep',
    activeHelpScreen: null,
    isProcessing: false,
    productData: {
      name: '',
      description: '',
      amount: null,
      recurring: true,
      recurring_interval: '',
      frequency: "monthly",
      each: 1,
      on: [5],
      count: null,
      recurringString: ''
    },
    productProperties: [],
    baseEquipments: []
  }),
  actions: {
    addProductProperty() {
      this.productProperties.push({
        label: '',
        field_type: 'text',
        required: false
      });
    },
    removeProductProperty(index) {
      this.productProperties.splice(index, 1);
    },
    addEquipment() {
      const priceInputs = {};
      for (let i = 1; i <= 10; i++) {
        priceInputs[i] = '';
      }

      this.baseEquipments.push({
        name: '',
        max_count: '3', // String to match select value type
        price_split: {},
        priceInputs,
        properties: []
      });
    },
    removeEquipment(index) {
      this.baseEquipments.splice(index, 1);
    },
    addEquipmentProperty(equipIndex) {
      this.baseEquipments[equipIndex].properties.push({
        label: '',
        field_type: 'text',
        required: false
      });
    },
    removeEquipmentProperty(equipIndex, propIndex) {
      this.baseEquipments[equipIndex].properties.splice(propIndex, 1);
    }
  }
});
