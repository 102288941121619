<template>
  <div class="help-section">
      <p class="bold-font emerald">This is the name that will appear on the contract page for your clients.</p>
      <div>
        Choose a clear and professional name that describes the service agreement.
      </div>
      <div class="bold-font mt-10">Example Contract Names:</div>
      <ul class="grey-text text-darken-2">
        <li>✔️ HVAC Quarterly Maintenance Agreement</li>
        <li>✔️ Air Conditioner & Furnace Protection Plan</li>
        <li>✔️ Home Appliance Care Plan</li>
        <li>✔️ Washer & Dryer Maintenance Agreement</li>
        <li>✔️ Whole-Home Plumbing Care Agreement</li>
      </ul>
      <div class="small-font grey-text text-lighten-1">
        * Keep it short and clear. Avoid using technical terms or abbreviations.
      </div>
  </div>
</template>

<script>
export default {
  name: 'ProductName'
}
</script>
