<template>
  <div class="help-section">
      <p class="bold-font emerald">This is the description that will be shown on the contract page for your clients.</p>
      <div>
        Provide a brief overview of the services covered under this contract. Highlight key benefits and what the client can expect.
      </div>
      <div class="bold-font mt-10">Example Descriptions:</div>
      <ul class="grey-text text-darken-2">
        <li>✔️ Quarterly maintenance for your HVAC system, including inspection, cleaning, and priority service.</li>
        <li>✔️ Full-service appliance repair coverage for refrigerators, ovens, washers, and dryers.</li>
        <li>✔️ Annual plumbing check-up, leak detection, and emergency repair service.</li>
        <li>✔️ Comprehensive heating and cooling plan with seasonal tune-ups and filter replacements.</li>
        <li>✔️ Water heater service contract covering inspections, flushes, and repairs.</li>
      </ul>
      <div class="small-font grey-text text-lighten-1">
        * Keep it clear and concise. Focus on what the contract includes and its benefits.
      </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDescription'
}
</script>
