<template>
  <div class="help-section">
    <p class="bold-font emerald">Name the equipment or service clients can select.</p>
    <div>
      This will appear in the contract as an option for clients. It can be a physical item (e.g., AC Unit, Dishwasher) or a service (e.g., Deep Cleaning, Inspection).
    </div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentsName'
}
</script>
