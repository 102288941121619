<template>
  <div>
    <div v-if="$apollo.loading" class="center">
      <i class="fas fa-spinner fa-spin"></i> Updating balance...
    </div>
    <div v-else>
      <div>
        <span class="bold-font grey-text text-darken-2">Funds Available for Instant Payout:</span><br>
        <div class="mt-5">
          <i class="fas fa-bolt"></i>
          <span>Available Now: {{balances.instantAvailable | currency}}</span>
          <span v-if="withdrawal"> | <slot name="instant"></slot></span>
          <br>
          <i class="fas fa-clock"></i>
          Pending: <span class="bold-font">{{balances.notCaptured | currency}}</span>
          <span v-if="canCapture"> | <slot name="pending"></slot></span>
        </div>
      </div>
      <div class="mt-10">
        <a href @click.prevent="refetchBalances"><i class="fas fa-sync"></i> Refetch</a>
      </div>
    </div>
  </div>
</template>
<script>
  import gql from 'graphql-tag'

  export default {
    data() {
      return {
        balances: {
          instantAvailable: null,
          notCaptured: null
        }
      }
    },
    apollo: {
      balances: {
        query: gql`
          query GetMerchantData {
            merchant {
              merchantUser {
                balances {
                  instantAvailable
                  notCaptured
                }
              }
            }
          }
        `,
        update: data => data.merchant.merchantUser.balances,
        error(e) {
          this.gqlErrorHandler(e);
        }
      }
    },
    methods: {
      async refetchBalances() {
        try {
          await this.$apollo.queries.balances.refetch();
        } catch (error) {
          console.log("Error refetching balances");
        }
      }
    },
    computed: {
      withdrawal() {
        return this.balances.instantAvailable > 0.55;
      },
      canCapture() {
        return this.balances.notCaptured > 0.55;
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.initMaterializeModals();
      });
    },
    updated() {
      this.$nextTick(() => {
        this.initMaterializeModals();
      });
    },
    methods: {
      async refetchBalances() {
        try {
          await this.$apollo.queries.balances.refetch();
          this.$nextTick(() => {
            this.initMaterializeModals();
          });
        } catch (error) {
          console.log("Error refetching balances");
        }
      },
      initMaterializeModals() {
        if (typeof M !== 'undefined' && M.Modal) {
          const elems = document.querySelectorAll('.modal');
          if (elems.length > 0) {
            M.Modal.init(elems);
          }
        }
      }
    }
  }
</script>
