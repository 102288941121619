// app/javascript/services/toast.js
export const ToastService = {
  error(message) {
    if (!message) return;
    // Check if toast container exists and cleanup
    const existingToasts = document.querySelectorAll('.toast');
    existingToasts.forEach(toast => M.Toast.dismissAll());

    // Use existing Materialize instance
    M.toast({ html: message, classes: 'red darken-1' });
  },

  success(message) {
    if (!message) return;
    const existingToasts = document.querySelectorAll('.toast');
    existingToasts.forEach(toast => M.Toast.dismissAll());

    M.toast({ html: message, classes: 'success-toast' });
  }
}
