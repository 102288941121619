<template>
  <section class="center">
    <!-- Validation Errors -->
    <div v-if="showValidationErrors" class="card-panel red lighten-4 red-text text-darken-4 mb-20">
      Please fill in all required fields correctly:
      <ul class="mt-10">
        <li v-if="store.baseEquipments.length === 0">
          • At least one equipment or service is required
        </li>
        <template v-for="(equipment, eqIndex) in store.baseEquipments">
          <li :key="'eq-name-' + eqIndex" v-if="!equipment.name || !equipment.name.trim()">
            • Equipment {{eqIndex + 1}} name is required
          </li>
          <li :key="'eq-price-' + eqIndex" v-if="!areAllPricesFilled(eqIndex)">
            • Equipment {{eqIndex + 1}} requires prices for all unit counts
          </li>
          <li :key="'eq-prop-' + eqIndex" v-if="!equipment.properties || equipment.properties.length === 0">
            • Equipment {{eqIndex + 1}} requires at least one property
          </li>
          <template v-for="(prop, pIndex) in equipment.properties">
            <li :key="'eq-prop-label-' + eqIndex + '-' + pIndex" v-if="!prop.label || !prop.label.trim()">
              • Equipment {{eqIndex + 1}} property {{pIndex + 1}} label is required
            </li>
          </template>
        </template>
      </ul>
    </div>
    <div class="card-content">
      <h3 class="bold-font">Define Equipment</h3>
      <div v-for="(equipment, eqIndex) in store.baseEquipments" :key="eqIndex" class="card">
        <div class="card-content" style="position: relative;">
          <i
            class="fas fa-times grey-text text-darken-1"
            @click="removeEquipment(eqIndex)"
            style="position: absolute; top: 10px; right: 10px; cursor: pointer; font-size: 18px;"
            title="Remove equipment"
          ></i>
          <div class="input-field">
            <label :for="'equip_name_' + eqIndex">Equipment or Service Name (required)</label>
            <input :id="'equip_name_' + eqIndex" 
                   v-model="equipment.name" 
                   @focus="store.activeHelpScreen = 'equipmentName'"
                   type="text" 
                   placeholder="e.g. AC Unit">
          </div>
          <div class="input-field">
            <div class="left">
              <label :for="'equip_max_count_' + eqIndex" class="active">Maximum Units per contract</label>
            </div>
            <div class="mt-5">
              <select :id="'equip_max_count_' + eqIndex" 
                      v-model="equipment.max_count" 
                      @change="updatePriceSplitFields(eqIndex)"
                      class="browser-default">
                <option v-for="n in 10" :key="n" :value="String(n)">
                  {{ n }} unit<template v-if="n > 1">s</template>
                </option>
              </select>
            </div>
          </div>

          <div class="price-split-container">
            <h6>Price per Unit Count</h6>
            <div v-for="n in parseInt(equipment.max_count)" :key="n" class="input-field">
              <label :for="'equip_price_' + eqIndex + '_' + n">
                Price for {{ n }}{{ 
                  n === 1 ? 'st' : 
                  n === 2 ? 'nd' : 
                  n === 3 ? 'rd' : 
                  'th' 
                }} unit (required)
              </label>
              <input :id="'equip_price_' + eqIndex + '_' + n"
                     type="number"
                     step="0.01"
                     min="0"
                     @input="updatePriceSplit(eqIndex)"
                     v-model="equipment.priceInputs[n]"
                     @focus="store.activeHelpScreen = 'equipmentPrice'"
                     placeholder="Enter price">
            </div>
          </div>

          <div class="mt-20">
            <h5>Equipment Properties (required)</h5>
            <div v-for="(prop, pIndex) in equipment.properties" :key="pIndex" class="card">
              <div class="card-content" style="position: relative;">
                <i
                  class="fas fa-times grey-text text-darken-1"
                  @click="removeEquipmentProperty(eqIndex, pIndex)"
                  style="position: absolute; top: 10px; right: 10px; cursor: pointer; font-size: 18px;"
                  title="Remove property"
                ></i>
                <div class="input-field">
                  <label :for="'equip_prop_label_' + eqIndex + '_' + pIndex">Label (required)</label>
                  <input :id="'equip_prop_label_' + eqIndex + '_' + pIndex" 
                         v-model="prop.label" 
                         @focus="store.activeHelpScreen = 'equipmentProperty'"
                         type="text" 
                         placeholder="e.g. Serial Number">
                </div>
                <div class="input-field" style="display: none;">
                  <label :for="'equip_prop_field_type_' + eqIndex + '_' + pIndex">Field Type</label>
                  <select :id="'equip_prop_field_type_' + eqIndex + '_' + pIndex" v-model="prop.field_type">
                    <option value="text">Text</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </div>
                <div class="input-field">
                  <label>
                    <input type="checkbox" v-model="prop.required">
                    <span>Required</span>
                  </label>
                  <br>
                </div>
              </div>
            </div>
            <button class="btn" @click="addEquipmentProperty(eqIndex)">
              <i class="fas fa-plus"></i> 
              Add 
              <span v-if="equipment.properties.length == 0">Property</span>
              <span v-else>Another Property</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-20" v-if="store.baseEquipments.length > 0">
        <button class="btn teal add-btn" @click="addEquipment">
          <i class="fas fa-plus"></i>
          <span>ADD ANOTHER EQUIPMENT</span>
        </button>
      </div>
      <!-- Action Buttons -->
      <div class="mt-20 action-buttons" v-if="store.baseEquipments.length === 0">
        <button
          class="btn grey lighten-1 back-btn"
          @click="back"
        >
          <i class="fas fa-arrow-left"></i>
          <span>BACK</span>
        </button>
        <button
          class="btn teal add-btn"
          @click="addEquipment"
        >
          <i class="fas fa-plus"></i>
          <span>ADD EQUIPMENT</span>
        </button>
        <button
          class="btn grey lighten-1 skip-btn" 
          @click="skipEquipments"
        >
          <i class="fas fa-forward"></i>
          <span>SKIP</span>
        </button>
      </div>
    </div>
    <!-- Navigation Buttons -->
    <div class="card-action navigation-buttons" v-if="store.baseEquipments.length > 0">
      <button class="btn grey lighten-1 back-btn" @click="back">
        <i class="fas fa-arrow-left"></i>
        <span>BACK</span>
      </button>
      <button
        class="btn teal next-btn"
        @click="viewContract"
        :disabled="!isFormValid"
        :class="{ disabled: !isFormValid }"
      >
        <span>NEXT: VIEW CONTRACT</span>
      </button>
    </div>
  </section>
</template>

<script>
import { useProductStore } from '../product_store'
import { onMounted, nextTick, ref, computed } from '@vue/composition-api'

export default {
  name: 'BaseEquipments',
  setup() {
    const store = useProductStore();
    const showValidationErrors = ref(false);

    const isFormValid = computed(() => {
      if (store.baseEquipments.length === 0) return false;

      return store.baseEquipments.every(equipment => {
        // Check equipment name
        if (!equipment.name || !equipment.name.trim()) return false;

        // Check if all prices are filled based on max_count
        const maxCount = parseInt(equipment.max_count);
        for (let i = 1; i <= maxCount; i++) {
          if (!equipment.priceInputs || !equipment.priceInputs[i]) return false;
          const price = equipment.priceInputs[i];
          if (!price || isNaN(parseFloat(price)) || parseFloat(price) <= 0) {
            return false;
          }
        }

        // Check if equipment has at property with a label or no properties
        return equipment.properties.every(prop => prop.label && prop.label.trim());
      });
    });

    const areAllPricesFilled = (equipIndex) => {
      const equipment = store.baseEquipments[equipIndex];
      if (!equipment || !equipment.max_count || !equipment.priceInputs) return false;
      
      const maxCount = parseInt(equipment.max_count);
      for (let i = 1; i <= maxCount; i++) {
        if (!equipment.priceInputs[i]) return false;
        const price = equipment.priceInputs[i];
        if (!price || isNaN(parseFloat(price)) || parseFloat(price) <= 0) {
          return false;
        }
      }
      return true;
    };

    const updatePriceSplitFields = (equipIndex) => {
      const equipment = store.baseEquipments[equipIndex];
      const maxCount = parseInt(equipment.max_count);
      
      // Ensure all price inputs exist
      if (!equipment.priceInputs) {
        equipment.priceInputs = {};
      }
      
      // Make sure all possible values exist
      for (let i = 1; i <= 10; i++) {
        if (!(i in equipment.priceInputs)) {
          equipment.priceInputs[i] = '';
        }
      }
      
      updatePriceSplit(equipIndex);
    };

    const updatePriceSplit = async (equipIndex) => {
      const equipment = store.baseEquipments[equipIndex];
      const maxCount = parseInt(equipment.max_count);
      const priceSplit = {};
      
      // Only include values up to the current max_count
      for (let i = 1; i <= maxCount; i++) {
        const value = equipment.priceInputs[i];
        if (value && !isNaN(parseFloat(value))) {
          priceSplit[i] = parseFloat(value);
        }
      }
      
      equipment.price_split = priceSplit;

      await nextTick();
      M.updateTextFields();
    };

    const addEquipment = async () => {
      store.addEquipment();
      await nextTick();
      M.updateTextFields();
    };

    const addEquipmentProperty = async (equipIndex) => {
      store.addEquipmentProperty(equipIndex);
      await nextTick();
      M.updateTextFields();
    };

    const removeEquipment = (index) => store.removeEquipment(index);
    const removeEquipmentProperty = (equipIndex, propIndex) => 
      store.removeEquipmentProperty(equipIndex, propIndex);

    const back = () => {
      store.activeHelpScreen = 'productProperties'
      store.activeStep = 'productProperties'
    };

    const viewContract = () => {
      if (!isFormValid.value) {
        showValidationErrors.value = true;
        return;
      }
      store.activeHelpScreen = ''
      store.activeStep = 'viewContract';
    };

    const skipEquipments = () => {
      // Clear any existing equipments
      store.baseEquipments = [];
      // Move to the next step
      store.activeHelpScreen = '';
      store.activeStep = 'viewContract';
    };

    onMounted(async () => {
      await nextTick()
      M.updateTextFields()
    });
    
    return {
      store,
      back,
      addEquipment,
      removeEquipment,
      addEquipmentProperty,
      removeEquipmentProperty,
      viewContract,
      skipEquipments,
      updatePriceSplitFields,
      updatePriceSplit,
      showValidationErrors,
      isFormValid,
      areAllPricesFilled
    }
  }
}
</script>

<style scoped>
.price-split-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.price-split-container h6 {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 500;
}

.browser-default {
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  height: 40px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.action-buttons .btn {
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 16px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  margin: 5px;
  flex: 1;
  min-width: 100px;
  max-width: 200px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.navigation-buttons .btn {
  text-transform: uppercase;
  font-weight: 500;
  flex: 1;
  margin: 5px;
  min-width: 120px;
}

/* Mobile responsiveness */
@media only screen and (max-width: 600px) {
  .action-buttons, .navigation-buttons {
    flex-direction: column;
  }
  
  .action-buttons .btn, .navigation-buttons .btn {
    width: 100%;
    max-width: 100%;
  }
}
</style>
