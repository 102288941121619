<template>
  <section class="center">
    <div class="card-content">
      <h3 class="bold-font">{{store.productData.name}}</h3>

      <div class="grey-text text-darken-2">{{store.productData.description}}</div>
      <div class="large-font bold-font emerald" v-if="store.productData.amount > 0">
        Base price: {{store.productData.amount | currency}}
      </div>
      <div v-if="store.productData.recurring" class="bold-font emerald">
        Recurring: {{store.productData.recurringString}}
      </div>
      
      <!-- Properties Section -->
      <div class="section-container" v-if="store.productProperties.length > 0">
        <h5 class="bold-font">Contract Properties</h5>
        <div v-for="(prop, index) in store.productProperties" :key="'prop-'+index" class="detail-item">
          <strong>{{ prop.label }}:</strong> 
          <span v-if="prop.required" class="badge-grey-outlined-condenced">Required</span>
          Text field
        </div>
      </div>

      <!-- Equipment Section -->
      <div class="section-container" v-if="store.baseEquipments.length > 0">
        <h5 class="bold-font">Equipment and Services</h5>
        <div v-for="(equipment, eqIndex) in store.baseEquipments" :key="'eq-'+eqIndex" class="equipment-card">
          <h6>{{ equipment.name }}</h6>
          
          <!-- Price Split -->
          <div class="subsection">
            <strong>Price per Unit:</strong>
            <div v-for="(price, units) in equipment.price_split" :key="'price-'+units" class="detail-item">
              {{ units }} unit{{ units > 1 ? 's' : '' }}: {{ price | currency }}
            </div>
          </div>

          <!-- Properties -->
          <div class="subsection" v-if="equipment.properties.length > 0">
            <strong>Equipment Properties:</strong>
            <div v-for="(prop, pIndex) in equipment.properties" :key="'eq-prop-'+eqIndex+'-'+pIndex" class="equipment-detail-item">
              {{ prop.label }}: 
              <span v-if="prop.required" class="badge-grey-outlined-condenced">Required</span>
              Text field
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-action" v-show="notFinished">
      <button class="btn grey" @click="back" :disabled="store.isProcessing">
        <i class="fas fa-arrow-left"></i>
        Back
      </button>
      <div v-if="isSubmitDisabled" class="error-message mt-10">
        <i class="fas fa-exclamation-circle"></i>
        Please define an amount either as base price or on per equipment basis
      </div>
      <button class="btn" @click="submitContract" :disabled="store.isProcessing || isSubmitDisabled">
        <i :class="store.isProcessing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"></i>
        {{ store.isProcessing ? 'Processing...' : 'Submit Contract' }}
      </button>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'
import { ref } from "@vue/composition-api";

import { useProductStore } from '../product_store'

const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct(
    $name: String!, 
    $description: String!, 
    $amountCents: Int!, 
    $recurring: Boolean!,
    $recurringParams: RecurringAttributes,
    $productProperties: [ProductPropertyAttributes!]!,
    $baseEquipments: [BaseEquipmentAttributes!]!
  ) {
    createProduct(input: {
      name: $name,
      description: $description,
      amountCents: $amountCents,
      recurring: $recurring,
      recurringParams: $recurringParams,
      productProperties: $productProperties,
      baseEquipments: $baseEquipments
    }) {
      message
      errors
      product {
        id
      }
    }
  }
`

export default {
  name: 'ViewContract',
  setup() {
    const store = useProductStore()
    const notFinished = ref(true)
    return { store, notFinished }
  },
  computed: {
    isSubmitDisabled() {
      return (!this.store.productData.amount || this.store.productData.amount === 0) && 
             this.store.baseEquipments.length === 0;
    }
  },
  methods: {
    back() {
      this.store.activeStep = 'baseEquipments'
      this.store.activeHelpScreen = "baseEquipments";
    },
    async submitContract() {
      this.store.isProcessing = true

      try {
        const productProperties = this.store.productProperties.map(prop => ({
          label: prop.label,
          fieldType: prop.field_type,
          required: prop.required
        }))

        const baseEquipments = this.store.baseEquipments.map(eq => ({
          name: eq.name,
          description: eq.description || '',
          maxCount: parseInt(eq.max_count),
          priceSplit: eq.price_split,
          properties: eq.properties.map(prop => ({
            label: prop.label,
            fieldType: prop.field_type,
            required: prop.required
          }))
        }))

        const recurringParams = this.store.productData.recurring ? {
          frequency: this.store.productData.frequency,
          each: this.store.productData.each,
          on: this.store.productData.on,
          count: this.store.productData.count
        } : null

        const response = await this.$apollo.mutate({
          mutation: CREATE_PRODUCT_MUTATION,
          variables: {
            name: this.store.productData.name,
            description: this.store.productData.description,
            amountCents: Math.round(this.store.productData.amount * 100),
            recurring: this.store.productData.recurring,
            recurringParams: recurringParams,
            productProperties: productProperties,
            baseEquipments: baseEquipments
          }
        })

        if (response.data.createProduct.errors?.length > 0) {
          this.notifyError(response.data.createProduct.errors.join(', '))
        } else {
          this.notifySuccess(response.data.createProduct.message)
          this.notFinished = false
          setTimeout(() => Turbolinks.visit('/merchant/products'), 2000)
        }
      } catch (error) {
        this.notifyError('Failed to create product: ' + error.message)
      } finally {
        this.store.isProcessing = false
      }
    }
  }
}
</script>

<style scoped scss>
.section-container {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.equipment-card {
  margin: 15px 0;
  padding: 15px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.detail-item {
  margin: 8px 0;
  padding: 8px;
  background-color: white;
  border-radius: 2px;
}

.equipment-detail-item {
  margin: 8px 0;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 2px;
}

.subsection {
  margin: 10px 0;
}

h6 {
  font-family: helvetica-bold, sans-serif  !important;
  font-weight: 900 !important;
  margin: 0 0 10px 0;
}

.btn i {
  margin-right: 5px;
}

.error-message {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.error-message i {
  margin-right: 8px;
  font-size: 18px;
}
</style>
