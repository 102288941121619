<template>
  <div class="help-section">
    <p class="bold-font emerald">Define the key details your contract needs.</p>
    <div>
      You are creating labels for fields that clients will fill in when signing the contract. These fields help you collect important information specific to each client.
    </div>
    <div class="bold-font mt-10">Common Properties:</div>
    <ul class="grey-text text-darken-2">
      <li>• Client Name (Client enters their full name)</li>
      <li>• Address (Client provides their service location)</li>
      <li>• Phone Number (Client’s contact number)</li>
      <li>• Email (Client’s email address for communication)</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Keep it simple. Too many fields can make the process overwhelming for clients.
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertiesHelp'
}
</script>
