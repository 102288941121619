<template>
  <div class="help-section">
    <p class="bold-font emerald">Define the equipment or services clients can choose.</p>
    <div>
      Add multiple equipment options that clients can select when signing the contract. Each equipment item can have a name, pricing rules, and custom fields.
    </div>
    <div class="bold-font mt-10">Key Details:</div>
    <ul class="grey-text text-darken-2">
      <li>• Each equipment or service has a name (e.g., AC Unit, Furnace, Chimney Cleaning).</li>
      <li>• You can limit the maximum number of units a client can select.</li>
      <li>• Pricing can be customized per unit (e.g., first unit $30, second $20, third $10).</li>
      <li>• Equipment can be a <span class="emerald bold-font">physical item</span> (e.g., HVAC System, Washing Machine) or a <span class="emerald bold-font">service</span> (e.g., Deep Cleaning, Annual Inspection).</li>
      <li>• Each equipment item can have custom fields (e.g., Serial Number, Model) that clients will fill in.</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Make sure to only add relevant equipment or services that apply to this contract.
    </div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentsHelp'
}
</script>
