<template>
<div class="help-section">
    <p class="bold-font emerald">This is the field name clients will see.</p>
    <div>
      Use a clear and simple label that helps clients understand what information is required. 
    </div>
    <div class="bold-font mt-10">Example Labels:</div>
    <ul class="grey-text text-darken-2">
      <li>• Client name</li>
      <li>• Service Address</li>
      <li>• Preferred Contact Time</li>
      <li>• Additional Notes</li>
    </ul>
    <div class="small-font grey-text text-lighten-1">
      * Avoid vague or technical terms. Make it easy for clients to fill out.
    </div>
</div>
</template>

<script>
export default {
  name: 'PropertiesHelp'
}
</script>
