<template>
  <div>
    <section class="main-form" v-show="!showPaymentDetails && !showLocationConfirmation && !showItemForm">
      <div class="row">
        <div class="input-field col s12 m6" v-show="items.length == 0">
          <i class="fas fa-dollar-sign prefix grey-text"></i>
          <input 
            @input="updateAmounts" 
            name="amount"
            id="amount"
            autofocus
            v-model='amount'
            step="0.01"
            type="text" 
            inputmode="decimal"
            v-validate="'min_value:0|required'"
            class='inputText'
            lang="en-001">
            <span class='red-text small-font' v-show='errors.first("amount")'>{{errors.first("amount")}}</span>

          <label for="amount">Amount</label>
        </div>
        <div class="row" v-show="items.length > 0">
            <div class="col s12 m6">
              <span class="items-amount bold-font emerald">{{amount | currency}}</span>
            </div>
            <div class="col s12 m6">
              <show-items :items="items" :items-updated="itemsUpdated"></show-items>
            </div>
        </div>
        <div class="input-field col s12 m6" v-show="!skipRemoteSignature">
          <vue-tel-input v-model="phone" wrapperClasses="customPhoneInput" placeholder="Cardholder phone number"></vue-tel-input>
        </div>
        <div v-if="items.length == 0">
          <div class="input-field col s12 m6">
            <i class="fas fa-file-invoice prefix grey-text"></i>
            <input name="invoice" id="invoice" type="text" v-model="invoice" ref="invoice">

            <label for="invoice">{{invoiceLabel}}</label>
          </div>

          <div class="input-field col s12 m6" v-if="requireInvoiceConfirmation">
            <i class="fas fa-file-invoice prefix grey-text"></i>
            <input name="invoice-cfm" id="invoice-cfm" type="text" v-validate="{required: invoice.length > 0, confirmed: 'invoice'}">

            <label for="invoice-cfm">Invoice (confirmation)</label>
            <span v-show="errors.has('invoice-cfm')" class="red-text">Invoice numbers do not match</span>
          </div>

          <div class="input-field col s12 m6">
            <i class="fas fa-sticky-note prefix grey-text"></i>
            <input name="note" id="note" type="text" v-model="note" ref="note">

            <label for="note">Note (Optional)</label>
          </div>
        </div>
      </div>

      <slot :update-token="updateToken"></slot>

      <div v-show="errorMessage || processingErrorMessage">
        <span class='red-text'>{{errorMessage}}</span><br>
        <span class='red-text'>{{processingErrorMessage}}</span><br>
      </div>

      <div class="row" style="margin-top: -25px">
        <div class="col s12 m4">
          <button class="btn btn-small outlined-btn" style="margin-top: 21px" @click="showItemForm=true" v-show='!showPending'>
            <i class="fas fa-plus"></i>
            Add Item
          </button>
        </div>
        <div class="col s12 m8" v-if="showContracts && !skipRemoteSignature && !showPending">
          <label>Contract</label>
          <select v-model="selectedProduct" class="browser-default">
            <option value="" disabled>Select a Contract</option>
            <option v-for="product in products" :key="product.id" :value="product.id">
              {{ product.name | truncate(50) }}
            </option>
            <option :value="null">No Contract</option>
          </select>
          <p v-if="selectedProductDescription" class="grey-text">{{ selectedProductDescription | truncate(50, "...") }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m4">
          <div v-show="showTips">
            <span class="grey-text">Allow tipping for this transaction</span>
            <div class="switch">
              <label>
                Off
                <input type="checkbox" v-model="perTransactionTips">
                <span class="lever"></span>
                On
              </label>
            </div>
          </div>
        </div>
        <div class="col s12 m4">
          <div v-show="showFeedbackHub">
            <span class="grey-text">Allow Votes/Feedback</span>
            <div class="switch">
              <label>
                Off
                <input type="checkbox" v-model="perTransactionVotes">
                <span class="lever"></span>
                On
              </label>
            </div>
          </div>
        </div>
        <div class="col s12 m4">
          <div v-show="showFeedbackHub">
            <span class="grey-text">Skip Remote Signature</span>
            <div class="switch">
              <label>
                Off
                <input type="checkbox" v-model="skipRemoteSignature">
                <span class="lever"></span>
                On
              </label>
            </div>
          </div>
        </div>
      </div>

      <button @click="validateAndProcess(checkDuplicate)" v-show="!showPending && !skipRemoteSignature" class="btn-large wide-btn mt-10" :disabled="disableSignupBtn || !phoneIsValid || !submittable">
        Request remote signature<span v-show='amount' class="hide-on-med-and-down"> for {{amount | currency}}</span>
      </button>

      <button @click="validateAndProcess(checkDuplicate)" v-show="!showPending && skipRemoteSignature" class="btn-large wide-btn mt-10" :disabled="disableSignupBtn || !phoneIsValid || !submittable">
        Skip remote signature<span v-show='amount' class="hide-on-med-and-down"> for {{amount | currency}}</span>
      </button>
      <div class="center grey-text" v-if="feeAmount > 0">
        Gross amount: <b>{{grossAmount | currency}}</b> |  
        Non-cash adj: <b>{{feeAmount | currency}}</b>
      </div>
    </section>
    <center v-show='showPending && !showPaymentDetails' class='grey-text mt-25'>
      <i class="fas fa-spinner fa-spin"></i> Processing...
    </center>

    <payment-details v-show="showPaymentDetails" :amount="transactionAmount" :id="transactionId"></payment-details>

    <items
      v-if="showItemForm"
      :items="items"
      :items-updated="itemsUpdated"
      :set-invoice="invoice"
      :set-note="note"
      :set-amount="items.length > 0 ? null : amount"
      :invoice-label="invoiceLabel"
      :require-invoice-confirmation="requireInvoiceConfirmation"
      @close="showItemForm=false">

    </items>

    <!-- Show location confirmation screen E-Sign Flow-->
    <section v-show="showLocationConfirmation && !showPending">
      <h3 class="emerald">
        Are you sure that this transaction is for {{groupLocation}}?
      </h3>

      <div class="mt-25">
        <button class="btn btn-large outlined-btn" @click="showLocationConfirmation=false">No</button>
        <button class="btn btn-large" style="margin-left: 20px;" @click="validateAndProcess(processPayment)">Yes</button>
      </div>
    </section>
  </div>
</template>
<script>
  import gql from 'graphql-tag'
  import paymentDetails from '../manual_charge/payment_details'
  import items from '../generic_cards/items'
  import showItems from '../generic_cards/show_items'

  export default {
    props: ['requireInvoiceField','requireInvoiceConfirmation', 'setAmount', 'setPhone', 'setNote', 'setInvoice', 'showTips', 'groupsEnabled', 'groupLocation', 'qid', 'surchargeEligible', 'showFeedbackHub', 'showContracts'],
    components: {paymentDetails, items, showItems},
    apollo: {
      merchantData: {
        query: gql`query {
          merchant {
            products(state: active) {
              name
              description
              id
              default
            }
          }
        }`,
        update: data => data.merchant,
        result({ data }) {
          if (data && data.merchant) {
            // Set products
            this.products = data.merchant.products || [];

            const defaultProduct = this.products.find(p => p.default);
            if (defaultProduct) {
              this.selectedProduct = defaultProduct.id;
            }
          }
        },
        skip() {
          // Skip this query if showContracts is false
          return !this.showContracts;
        }
      }
    },
    data() {
      return {
        amount: this.setAmount,
        phone: this.setPhone,
        errorMessage: null,
        processingErrorMessage: null,
        disableSignupBtn: false,
        showPending: false,
        phoneIsValid: true,
        transactionAmount: null,
        note: this.setNote,
        invoice: this.setInvoice,
        cardToken: null,
        funding: null,
        showPaymentDetails: false,
        transactionId: null,
        perTransactionTips: this.showTips,
        perTransactionVotes: true,
        showLocationConfirmation: false,
        items: [],
        showItemForm: false,
        feeAmount: 0,
        grossAmount: 0,
        withTipAmount: 0,
        tipAmount: 0,
        tipAmountFormatted: 0,
        lastGrossCalcRequestId: 0,
        performCheckDuplicate: true,
        skipRemoteSignature: false,
        products: [],
        selectedProduct: ""
      }
    },
    methods: {
    validateAndProcess(func) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          func()
        } else {
          this.showPending = false
          this.showLocationConfirmation = false
          this.notifyError("Please complete all required fields!")
        }
      })
    },
    checkDuplicate() {
      this.showPending = true

      if (!this.performCheckDuplicate) {
        this.requestEsign()
        return
      }

      this.$apollo.query({
        // Query
        query: gql`query($amount: Float!, $invoice: String, $flow: PaymentFlows!) {
          merchant {
            checkDuplicate(amount: $amount, invoice: $invoice, flow: $flow) {
              duplicate
              messages
            }
          }
        }`,
        // Parameters
        variables: {
          amount: parseFloat(this.amount),
          invoice: this.invoice,
          flow: "card"
        },
      }).then(result => {
        this.showPending = false
        if (result.data.merchant.checkDuplicate.duplicate) {
          this.errorMessage = result.data.merchant.checkDuplicate.messages[0]
          this.performCheckDuplicate = false
        } else {
          this.requestEsign()
        }
      }, error => {
        this.showPending = false
        this.gqlErrorHandler(error)
      })
    },
    requestEsign() {
      // If conjunction groups enabled we will show extra confirmation window
      // to makes sure we're submitting to the correct location
      if(this.groupsEnabled) {
        this.showPending = false
        this.showLocationConfirmation = true
      } else {
        this.validateAndProcess(this.processPayment)
      }
    },
    remoteCharge() {
        this.processingErrorMessage = null
        this.errorMessage = null
        this.showPending = true
        this.showLocationConfirmation = false
        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($amount: Float!, $phone: String!, $note: String, $invoice: String, $token: String!, $perTransactionTips: Boolean, $items: [ItemAttributes!], $showFeedbackHub: Boolean, $productId: ID) {
            chargeCardRemote(input: {amount: $amount, phone: $phone, note: $note, invoice: $invoice, token: $token, perTransactionTips: $perTransactionTips, items: $items, showFeedbackHub: $showFeedbackHub, productId: $productId}) {
              transaction { deprecatedId }
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: parseFloat(this.amount),
            note: this.note,
            invoice: this.invoice,
            phone: this.phone,
            token: this.cardToken,
            perTransactionTips: this.perTransactionTips,
            items: this.items,
            showFeedbackHub: this.perTransactionVotes,
            productId: this.selectedProduct || null
          },
        }).then(result => {
          if (result.data.chargeCardRemote.errors.length > 0) {
            this.showPending = false
            this.showLocationConfirmation = false
            this.errorMessage = result.data.chargeCardRemote.errors[0]
            this.notifyError(result.data.chargeCardRemote.errors[0])
          } else {
            console.log(result.data)
            this.transactionId = result.data.chargeCardRemote.transaction.deprecatedId
            this.showPaymentDetails = true
          }
        }, error => {
          this.showPending = false
          this.showLocationConfirmation = false
          this.gqlErrorHandler(error)
        })
      },
      directCharge() {
        this.processingErrorMessage = null
        this.errorMessage = null
        this.showPending = true
        this.showLocationConfirmation = false

        this.$apollo.mutate({
          // Query
          mutation: gql`mutation ($amount: Float!, $tipPercent: Int, $tipAmount: Float, $phone: String, $note: String, $invoice: String, $token: String!, $items: [ItemAttributes!]) {
            chargeCardDirect(input: {amount: $amount, tipPercent: $tipPercent, tipAmount: $tipAmount, phone: $phone, note: $note, invoice: $invoice, token: $token, items: $items}) {
              transaction { deprecatedId authCode }
              errors
            }
          }`,
          // Parameters
          variables: {
            amount: parseFloat(this.amount),
            tipPercent: parseInt(this.selectedTip),
            tipAmount: parseFloat(this.tipCustomAmount),
            note: this.note,
            invoice: this.invoice,
            token: this.cardToken,
            items: this.items
          },
        }).then(result => {
          if (result.data.chargeCardDirect.errors.length > 0) {
            this.showPending = false
            this.errorMessage = result.data.chargeCardDirect.errors[0]
            this.notifyError(result.data.chargeCardDirect.errors[0])
          } else {
            window.location.href = '/client/ach_transactions/' + result.data.chargeCardDirect.transaction.deprecatedId
          }
        }, error => {
          this.showPending = false
          this.showLocationConfirmation = false
          this.gqlErrorHandler(error)
        })
      },
      itemsUpdated(amount) {
        this.amount = amount
        this.invoice = ""
        this.note = ""
        this.errorMessage = null
        this.processingErrorMessage = null
      },
      updateAmounts() {
        this.performCheckDuplicate = true
        this.errorMessage = null
        // For the frequent updates, we want to make sure we only use the latest request
        // because the requests are async and can come back in any order
        this.lastGrossCalcRequestId++;
        const currentRequestId = this.lastGrossCalcRequestId;

        if(this.surchargeEligible) {
          this.$http.get("/api/v1/vue/gross_calculations", {params: this.params}).then(response => {
            if (currentRequestId === this.lastGrossCalcRequestId) {
              this.grossAmount = response.body.gross_amount
              this.feeAmount = response.body.fee
              this.withTipAmount = response.body.amount_with_tip
              this.tipAmount = response.body.tip_amount
              this.tipAmountFormatted = response.body.tip_amount_formatted
            }
          })
        }
      },
      updateToken(token) {
        this.cardToken = token.token
        this.funding = token.funding
        if (token.token) {
          this.updateAmounts()
        }
      }
    },
    computed: {
      invoiceLabel() {
        return this.requireInvoiceField ? "Invoice # (required)" : "Invoice # (optional)" 
      },
      submittable() {
        return (this.amount >= 0 && this.cardToken)
      },
      params() {
        return {
          amount: this.amount,
          tip_percent: 0,
          tip_custom_amount: 0,
          qid: this.qid,
          funding: this.funding
        }
      },
      processPayment() {
        if(this.skipRemoteSignature) {
          return this.directCharge
        } else {
          return this.remoteCharge
        }
      },
      selectedProductDescription() {
        if (!this.selectedProduct) return null;
        const product = this.products.find(p => p.id === this.selectedProduct);
        return product ? product.description : null;
      }
    }
  }
</script>
<style scoped>
  .customPhoneInput {
    border: none !important;
  }
  .activeTip {
    background-color: #fff;
    border: 2px solid  #00ab8e;
    color: #00ab8e;
  }
  .items-amount {
    font-size: 3em !important;
  }
</style>
